<template>
  <div
    class="d-flex flex-column align-end snackbar-div"
  >
    <VSnackbar
      v-for="(item, index) in items"
      :key="index"
      v-model="item.show"
      class="white--text mt-2 snackbar"
      color="#4D4F67"
      :timeout="5000"
    >
      {{ item.text }}
      <VBtn
        class="ml-1"
        color="white"
        icon
        @click="item.show = false"
      >
        <VIcon>mdi-close</VIcon>
      </VBtn>
    </VSnackbar>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .snackbar {
    position: relative;
    .v-snack__wrapper {
      border-radius: 8px;
      min-width: auto;
      position: relative;
    }
  }
}

.snackbar-div {
  position: fixed;
  z-index: 1000;
  right: 40px;
  bottom: 70px;
}
</style>
